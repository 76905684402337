<template>
  <v-layout row wrap>
    <v-flex xs12>

      <template v-if="!isAuthenticated || accountStatus != 'active'">
        <v-alert type="error" class="my-3">
          You must be signed in to submit an ADIF log file. Please sign up or sign in below.
        </v-alert>
        <v-btn color="orange" to="/signup" tile class="ma-3">
          <v-icon left>{{$vuetify.icons.values.mdiAccountPlusOutline}}</v-icon> Sign Up
        </v-btn>
        <v-btn v-on:click="doSignIn" tile class="ma-3">
          <v-icon left>{{$vuetify.icons.values.mdiLoginVariant}}</v-icon> Sign In
        </v-btn>
      </template>

      <!-- <template v-if="isAuthenticated && accountStatus == 'active'">
        <v-btn color="red" to="/adifupload" tile class="ma-3">
          <v-icon left>{{$vuetify.icons.values.mdiArrowLeftBoldBox}}</v-icon> Start Over
        </v-btn>
      </template> -->

      <v-alert type="info" v-if="isAuthenticated && accountStatus == 'active'" class="my-3">
        Please note: This page is experimental for log parsing purposes and does not submit your logs.
      </v-alert>

      <v-stepper v-model="e1" v-if="isAuthenticated && accountStatus == 'active'">
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 1"
            step="1"
            color="primary"
          >
            Upload Logs For Verification
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="e1 > 2"
            step="2"
            color="primary"
          >
            Review Log Verification Results
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3"
          color="primary">
            Submit Logs For Credit
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <!-- ################################################################################ -->
          <v-stepper-content step="1" class="ma-3">
            <h2>Instructions</h2>
            <p>
              Click inside the box to upload one or more ADIF logs. Your logs will be 
              analyzed and you will see a report below.
            </p>
            <p>
              All files must be compliant with the ADIF specification, see <a href="https://adif.org">https://adif.org</a>.
              Required fields are: ...
            </p>
            <p>
              Preferred file naming convention is: <pre>station_callsign@park_id-yyyymmdd.adi</pre>
              <br/>
              For example: <pre>K8MH@US-1234-20220101.adi</pre>
              <br/>
              If the ADIF fields contain all required fields, the field data will take precidence over the filename.
            </p>
            <v-file-input
              v-model="files"
              color="black"
              counter
              label="Click here to upload ADIF log files"
              multiple
              outlined
              :show-size="1000"
              @click="clearResults">
              <template v-slot:selection="{ index, text }">
                <v-chip
                  color="amber darken-1"
                  dark
                  label
                  small>
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>

            <v-divider class="my-3"></v-divider>
            <!-- <v-btn
              color="yellow"
              class="mx-3"
              @click="resetStepper">
              <v-icon left>{{$vuetify.icons.values.mdiArrowLeft}}</v-icon> Start Over
            </v-btn> -->

            <v-btn 
              v-if="files && files.length > 0" 
              color="primary"
              class="mx-3"
              @click="verifyLog">
              <v-icon left>{{$vuetify.icons.values.mdiArrowRight}}</v-icon> 
              Verify {{ files.length }} log(s)
            </v-btn>


            <v-divider class="my-3"></v-divider>

              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(item,i) in 5"
                  :key="i"
                >
                  <v-expansion-panel-header disable-icon-rotate>
                    Item {{ item }}
                    <template v-slot:actions>
                      <v-icon color="warning">
                        {{ $vuetify.icons.values.mdiClose }}
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-divider class="my-3"></v-divider>

            <p></p>
          </v-stepper-content>

          <!-- ################################################################################ -->
          <v-stepper-content step="2" class="ma-3">

            <v-progress-circular
              class="mx-auto"
              indeterminate
              color="primary"
              v-if="loading_validated_files"
              size="150"
              width="10"
            >Loading...</v-progress-circular>

            <p v-if="log_submission_result"><b>{{ log_submission_result.data.adif_files.length }} file(s) parsed:</b></p>

            <v-expansion-panels v-if="log_submission_result">
              <v-expansion-panel v-for="file in log_submission_result.data.adif_files" 
                  :key="file.filename">
                
                <v-expansion-panel-header v-if="file.errors" disable-icon-rotate>
                  File: {{ file.filename }}
                  <template v-slot:actions>
                    <v-icon color="error">
                      {{ $vuetify.icons.values.mdiClose }}
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                
                <v-expansion-panel-header v-else-if="file.warnings" disable-icon-rotate>
                  File: {{ file.filename }}
                  <template v-slot:actions>
                    <v-icon color="warning">
                      {{ $vuetify.icons.values.mdiClose }}
                    </v-icon>
                  </template>
                </v-expansion-panel-header>

                <v-expansion-panel-header v-else disable-icon-rotate>
                  File: {{ file.filename }}
                  <template v-slot:actions>
                    <v-icon color="green">
                      {{ $vuetify.icons.values.mdiCheck }}
                    </v-icon>
                  </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-container>
                    <v-row v-for="error in file.errors" :key="error" class="my-1">
                      <v-col class="red">ERROR: {{ error }}</v-col>
                    </v-row>

                    <v-row v-for="warning in file.warnings" :key="warning" class="my-1">
                      <v-col class="yellow">WARNING: {{ warning }}</v-col>
                    </v-row>

                    <v-row v-for="info in file.infos" :key="info" class="my-1">
                      <v-col class="green">INFO: {{ info }}</v-col>
                    </v-row>
                    
                    <v-row>
                      <v-col>Provided station_callsign</v-col>
                      <v-col>{{ file.station_callsign || "NONE" }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col>Provided operator</v-col>
                      <v-col>{{ file.operator || "NONE" }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col>Provided my_sig_info</v-col>
                      <v-col>{{ file.my_sig_info || "NONE" }}</v-col>
                    </v-row>


                  </v-container>

                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>
                            Date
                          </th>
                          <th>
                            CW QSOs
                          </th>
                          <th>
                            Phone QSOs
                          </th>
                          <th>
                            Digital QSO
                          </th>
                          <th>
                            Total QSO
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(totals, date) in file.totals" :key="date">
                          <td>{{ date }}</td>
                          <td>{{ totals.CW }}</td>
                          <td>{{ totals.PHONE }}</td>
                          <td>{{ totals.DATA }}</td>
                          <td>{{ totals.TOTAL }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            
            
            <v-checkbox
              v-model="server_verification"
              v-if="log_submission_result"
              class="mx-3"
              label="All logs are properly formatted for submission."
            ></v-checkbox>

            <v-checkbox
              v-model="user_verification"
              v-if="log_submission_result"
              class="mx-3"
              label="I verify that these logs are correct and accurate."
            ></v-checkbox>

            <v-divider class="my-3"></v-divider>

            <v-btn
              v-if="log_submission_result"
              color="yellow"
              class="mx-3"
              @click="resetStepper">
              <v-icon left>{{$vuetify.icons.values.mdiArrowLeft}}</v-icon> Start Over
            </v-btn>


            <v-btn
              v-if="user_verification"
              color="primary"
              @click="e1 = 3">
              <v-icon left>{{$vuetify.icons.values.mdiArrowRight}}</v-icon>
              Submit {{ files.length }} log(s) to POTA
            </v-btn>

            <p></p>
          </v-stepper-content>
          
          <!-- ################################################################################ -->
          <v-stepper-content step="3" class="ma-3">
            <v-alert type="success">This is where we would submit logs.</v-alert>

            <v-divider class="my-3"></v-divider>

            <v-btn
              v-if="log_submission_result"
              color="yellow"
              class="mx-3"
              @click="resetStepper">
              <v-icon left>{{$vuetify.icons.values.mdiArrowLeft}}</v-icon> Start Over
            </v-btn>
            <p></p>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { Auth } from 'aws-amplify'

//import ActivationForm from '@/components/ActivationForm'


export default {
    mounted () {
    },
    methods: {
      isAuthenticated () {
        return this.$store.user.state.isAuthenticated;
      },

      doSignIn: function () {
        Auth.federatedSignIn();
      },

      resetStepper() {
        this.e1 = 1
        this.log_submission_result = null
      },

      clearResults () {
        this.log_submission_result = null
      },

      verifyLog () {
        this.clearResults();
        const formData = new FormData();

        for (let file of this.files) {
          formData.append('adif', file)
        }

        axios
          .post(`https://${process.env.VUE_APP_API_URL}/adif`, formData, { 
            headers: { 'Authorization': this.$store.state.user.token, 'Content-Type': 'multipart/form-data' } 
          })
          .then((res) => {
            console.log(res);
            this.log_submission_result = res;
            this.loading_validated_files = false;
            
          });
        this.e1 = 2;

        this.server_verification = true; // this.log_submission_result
      }
    },
    computed: mapState({
      activations: state => state.activations.data,
      loadingActivations: state => state.activations.loading,
      tableView: state => state.config.tableView,

      isAuthenticated: state => state.user.isAuthenticated,
      accountStatus: state => state.user.accountStatus
    }),
    components: {
    },
    data () {
      return {
        e1: 1,
        files: null,
        log_submission_result: null,
        user_verification: false,
        loading_validated_files: true,
        server_verification: false
      }
    }
}
</script>
